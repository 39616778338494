import {
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { createContext, FC, ReactNode, useState } from "react"

type Props = {
    icon?: ReactNode
    text?: string
}

const CollapsibleContext = createContext<{ onChildSelected: () => void }>({
    onChildSelected: () => {}
})

const NavigationCollapsible: FC<Props> = ({ text = "", icon, children }) => {
    const [subOpen, setSubOpen] = useState(false)

    return (
        <>
            <ListItem button onClick={() => setSubOpen(!subOpen)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {subOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={subOpen} timeout="auto">
                <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: "1rem" }}
                >
                    <CollapsibleContext.Provider
                        value={{ onChildSelected: () => setSubOpen(true) }}
                    >
                        {children}
                    </CollapsibleContext.Provider>
                </List>
            </Collapse>
        </>
    )
}

export default NavigationCollapsible

export { CollapsibleContext }
