import { SvgIcon } from "@material-ui/core"
import { ComponentProps, FC } from "react"
import "./ApplicationLogo.scss"

type Props = Omit<ComponentProps<typeof SvgIcon>, "classes">

const ApplicationLogo: FC<Props> = (props) => {
    return (
        <SvgIcon
            {...props}
            classes={{ root: "app-logo" }}
            viewBox="0 0 152.34 39.42"
        >
            <path d="M48.47 39.41a5.06 5.06 0 01-4.36-2.5L32.28 16.69 20.45 36.91a5.05 5.05 0 01-9.17-1L-.01 1.23h10.52l6.89 20.85 10.51-18a5.05 5.05 0 018.73 0l10.51 18 6.72-20.85h10.57L53.28 35.91a5.05 5.05 0 01-4.26 3.46 4.83 4.83 0 01-.54 0M98.9 38.78H63.69a4.42 4.42 0 01-4.2-5.8l2.76-8.42c2.69-8.8 12.08-8.79 16.07-8.8h17.59c.63 0 2.54 0 2.54-1.88 0-2.33-1.23-3.56-3.66-3.63H67.24l2.83-9 24.77.16c5 0 12.45 3.32 12.45 12.48 0 4.31-3.05 10.71-11.39 10.71H78.29c-5 0-7 .67-7.59 2.56v.1l-.88 2.67h31.82z" />
            <path d="M143.23 38.73h-8.84v-7.08h-26a4.42 4.42 0 01-2.65-8L136.18.84a4.42 4.42 0 017.07 3.54v18.39h9.11l-2.82 8.84h-6.28zm-21.58-15.92h12.74v-9.55z" />
        </SvgIcon>
    )
}

export default ApplicationLogo
