import { Box } from "@material-ui/core"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import de from "../../data/de"
import passwordValidation from "../../functions/validation/passwordValidation"
import CircularProgressButton from "../circularProgressButton/CircularProgressButton"
import FormPasswordField from "../inputs/form/FormPasswordField"
import PasswordRequirements from "./PasswordRequirements"

export type PasswordCreateFormValues = {
    password: string
    passwordConfirmation: string
}

type Props = {
    onSubmit: (values: PasswordCreateFormValues) => Promise<void>
    disabled?: boolean
}

const validationSchema = Yup.object().shape({
    password: passwordValidation,
    passwordConfirmation: Yup.string()
        .required(de.form.validationError.required)
        .oneOf([Yup.ref("password"), null], de.resetPassword.notMatching)
})

const PasswordCreateForm: FC<Props> = ({ onSubmit, disabled }) => {
    const initialValues: PasswordCreateFormValues = {
        password: "",
        passwordConfirmation: ""
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => {
                helpers.setStatus({ disabled: true })
                onSubmit(values).finally(() =>
                    helpers.setStatus({ disabled: false })
                )
            }}
        >
            <Form
                className="form"
                noValidate
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
            >
                <PasswordRequirements />
                <FormPasswordField
                    label={de.password}
                    name="password"
                    required
                    autoFocus
                    autoComplete="new-password"
                />
                <FormPasswordField
                    label={de.resetPassword.passwordConfirmation}
                    name="passwordConfirmation"
                    required
                    autoComplete="new-password"
                />
                <Box display="flex" justifyContent="flex-end">
                    <CircularProgressButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={"submit"}
                        disabled={disabled}
                        inProgress={disabled}
                        fullWidth
                    >
                        {disabled ? de.pleaseWait : de.save}
                    </CircularProgressButton>
                </Box>
            </Form>
        </Formik>
    )
}

export default PasswordCreateForm
