import { FC } from "react"
import { Route, Switch } from "react-router-dom"
import EnterPasswordAfterCreation from "../../pages/accountInitialization/ticketId/EnterPasswordAfterCreation"
import Login from "../../pages/login/Login"
import ResetPassword from "../../pages/resetPassword/ResetPassword"
import EnterPasswordAfterReset from "../../pages/resetPassword/ticketId/EnterPasswordAfterReset"
import NotFoundRedirect from "./NotFoundRedirect"
import RootRedirect from "./RootRedirect"
import RouteWrapper from "./RouteWrapper"

type Props = {}

const PublicRoutes: FC<Props> = (props) => {
    return (
        <Switch>
            <Route exact path="/login">
                <RouteWrapper>
                    <Login />
                </RouteWrapper>
            </Route>
            <Route path="/accountInitialization/:ticketId">
                <RouteWrapper>
                    <EnterPasswordAfterCreation />
                </RouteWrapper>
            </Route>
            <Route path="/resetPassword/:ticketId">
                <RouteWrapper>
                    <EnterPasswordAfterReset />
                </RouteWrapper>
            </Route>
            <Route path="/resetPassword">
                <RouteWrapper>
                    <ResetPassword />
                </RouteWrapper>
            </Route>
            <Route exact path="/">
                <RootRedirect />
            </Route>
            <Route>
                <NotFoundRedirect />
            </Route>
        </Switch>
    )
}

export default PublicRoutes
