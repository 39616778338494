import { FC, useEffect, useState } from "react"

type Props = {
    /**
     * start time of the countdown in seconds
     */
    intitialTime: number
}

/**
 * Renders a simple countdown. `initialTime` is reduced by 1 every second and rerenderd.
 *
 *
 * @param props
 * @returns ReactNode that contains the current value of the countdown, no html-tags included, only text
 */
const Countdown: FC<Props> = ({ intitialTime }) => {
    const [seconds, setSeconds] = useState(intitialTime)

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>
        if (seconds > 0) {
            timeout = setTimeout(() => {
                setSeconds((s) => s - 1)
            }, 1000)
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [seconds])

    const minutesStr = Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0")

    const secondsStr = (seconds % 60).toString().padStart(2, "0")

    return <>{`${minutesStr}:${secondsStr}`}</>
}

export default Countdown
