import AddIcon from "@material-ui/icons/Add"
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined"
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined"
import LaptopIcon from "@material-ui/icons/Laptop"
import ListIcon from "@material-ui/icons/List"
import LiveTvOutlinedIcon from "@material-ui/icons/LiveTvOutlined"
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined"
import PersonOutlineIcon from "@material-ui/icons/PersonOutline"
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined"
import { FC } from "react"
import de from "../../data/de"
import NavigationCollapsible from "./NavigationCollapsible"
import NavigationItem from "./NavigationItem"

type Props = {}

const AdminNavigation: FC<Props> = () => {
    return (
        <>
            <NavigationItem
                icon={<DashboardOutlinedIcon />}
                text={de.dashboard}
                to="/admin/dashboard"
            />
            <NavigationCollapsible
                icon={<PersonOutlineIcon />}
                text={de.user.title}
            >
                <NavigationItem
                    icon={<ListIcon />}
                    text={de.show}
                    to="/admin/users"
                />
                <NavigationItem
                    icon={<AddIcon />}
                    text={de.create}
                    to="/admin/users/new"
                />
            </NavigationCollapsible>
            <NavigationCollapsible
                icon={<BusinessOutlinedIcon />}
                text={de.company.title}
            >
                <NavigationItem
                    icon={<ListIcon />}
                    text={de.show}
                    to="/admin/companies"
                />
                <NavigationItem
                    icon={<AddIcon />}
                    text={de.create}
                    to="/admin/companies/new"
                />
            </NavigationCollapsible>
            <NavigationCollapsible
                icon={<ShoppingCartOutlinedIcon />}
                text={de.order.title}
            >
                <NavigationItem
                    icon={<ListIcon />}
                    text={de.show}
                    to="/admin/orders"
                />
                <NavigationItem
                    icon={<AddIcon />}
                    text={de.create}
                    to="/admin/orders/new"
                />
            </NavigationCollapsible>
            <NavigationCollapsible
                icon={<LiveTvOutlinedIcon />}
                text={de.playout.title}
            >
                <NavigationItem
                    icon={<ListIcon />}
                    text={de.show}
                    to="/admin/playouts"
                />
                <NavigationItem
                    icon={<AddIcon />}
                    text={de.create}
                    to="/admin/playouts/new"
                />
            </NavigationCollapsible>
            <NavigationCollapsible icon={<LaptopIcon />} text={de.system}>
                <NavigationItem
                    icon={<MenuBookOutlinedIcon />}
                    text={de.about.title}
                    to="/admin/about"
                />
            </NavigationCollapsible>
        </>
    )
}

export default AdminNavigation
