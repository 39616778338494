/**
 * generates a full name out of optional first- and lastname
 * @param firstname
 * @param lastname
 * @param fallback used if first- and lastname are empty
 * @returns the full name of the user
 */
const generateUserSalutation = (
    firstname?: string,
    lastname?: string,
    fallback = ""
) => {
    const stringsToJoin: string[] = []

    if (!!firstname && firstname.trim() !== "") stringsToJoin.push(firstname)
    if (!!lastname && lastname.trim() !== "") stringsToJoin.push(lastname)

    if (stringsToJoin.length > 0) return stringsToJoin.join(" ")

    return fallback
}

export default generateUserSalutation
