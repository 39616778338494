import { Typography } from "@material-ui/core"
import { FC, useState } from "react"
import { useParams } from "react-router"
import GuestCard from "../../../components/guestCard/GuestCard"
import PasswordCreateForm from "../../../components/passwordCreateForm/PasswordCreateForm"
import de from "../../../data/de"
import { AccountActivationDto } from "../../../api/account/types/AccountActivationDto"
import useActivation from "../../../api/account/useActivation"
import useDocumentTitle from "../../../hooks/useDocumentTitle"
import useRedirectIfLoggedIn from "../../../hooks/useRedirectIfLoggedIn"

type MatchParams = { ticketId: string }

const EnterPasswordAfterCreation: FC<{}> = () => {
    useDocumentTitle(de.createPassword.title)

    const params = useParams<MatchParams>()
    const activate = useActivation()
    const [error, setError] = useState("")
    const [disabled, setDisabled] = useState(false)

    useRedirectIfLoggedIn(de.resetPassword.alreadyLoggedIn)

    const onSubmit = (values: AccountActivationDto) => {
        if (params.ticketId) values.ticketId = params?.ticketId
        setDisabled(true)
        return activate(values).catch((err) => {
            setDisabled(false)
            if (err.errorCode === "INVALID_ID")
                setError(de.resetPassword.invalidTicket)
            else if (err.errorCode === "TICKET_EXPIRED")
                setError(de.resetPassword.expiredTicket)
            else if (err.errorCode === "ALREADY_LOGGED_IN")
                setError(de.resetPassword.alreadyLoggedIn)
            else setError(de.error.genericTryAgainLater)
        })
    }

    return (
        <GuestCard title={de.resetPassword.title}>
            {error && (
                <Typography component="h2" variant="subtitle1" color="error">
                    {error}
                </Typography>
            )}
            <PasswordCreateForm onSubmit={onSubmit} disabled={disabled} />
        </GuestCard>
    )
}

export default EnterPasswordAfterCreation
