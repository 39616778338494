import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined"
import { FC, useState } from "react"
import de from "../../../data/de"
import useFormikCrmInput from "../useFormikCrmInput"

type Props = {
    name: string
    label: string
    required?: boolean
    disabled?: boolean
    autoFocus?: boolean
    autoComplete?: string
}

/**
 * formik text input password component
 * @param props
 */
const FormPasswordField: FC<Props> = ({
    name,
    label,
    required,
    disabled,
    autoFocus,
    autoComplete = "current-password"
}) => {
    const [visible, setVisible] = useState(false)

    const [
        field,
        ,
        isDisabled,
        showError,
        errorToDisplay
    ] = useFormikCrmInput<string>(name, disabled)

    return (
        <TextField
            {...field}
            error={showError}
            helperText={errorToDisplay}
            id={name + "-password-input"}
            variant="outlined"
            margin="normal"
            fullWidth
            color="secondary"
            label={label}
            type={visible ? "text" : "password"}
            disabled={isDisabled}
            required={required}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={
                                visible
                                    ? de.login.hidePassword
                                    : de.login.showPassword
                            }
                            onClick={() => setVisible(!visible)}
                        >
                            {visible ? (
                                <VisibilityOffOutlinedIcon />
                            ) : (
                                <VisibilityOutlinedIcon />
                            )}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

export default FormPasswordField
