import { useSnackbar } from "notistack"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useUserInfoState } from "../store/userInfo/UserInfoStore"

const useRedirectIfLoggedIn = (messageOnRedirect: string, to = "/") => {
    const { loggedInUser } = useUserInfoState()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (loggedInUser !== null) {
            history.replace(to)
            enqueueSnackbar(messageOnRedirect, {
                variant: "error"
            })
        }
    }, [enqueueSnackbar, history, loggedInUser, messageOnRedirect, to])
}

export default useRedirectIfLoggedIn
