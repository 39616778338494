import { Box } from "@material-ui/core"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import CircularProgressButton from "../../../components/circularProgressButton/CircularProgressButton"
import FormTextField from "../../../components/inputs/form/FormTextField"
import de from "../../../data/de"
import emailValidation from "../../../functions/validation/emailValidation"

export type PasswordResetFormValues = {
    email: string
}

type Props = {
    onSubmit: (values: PasswordResetFormValues) => Promise<void>
    disabled?: boolean
}

const validationSchema = Yup.object().shape({
    email: emailValidation
})

const PasswordResetForm: FC<Props> = ({ onSubmit, disabled }) => {
    const initialValues: PasswordResetFormValues = {
        email: ""
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, helpers) => {
                helpers.setStatus({ disabled: true })
                onSubmit(values).finally(() =>
                    helpers.setStatus({ disabled: false })
                )
            }}
        >
            <Form className="form" noValidate>
                <FormTextField
                    name="email"
                    type="email"
                    autoFocus
                    label={de.email}
                    disabled={disabled}
                    required
                    autoComplete="email"
                />
                <Box display="flex" justifyContent="flex-end">
                    <CircularProgressButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={"submit"}
                        disabled={disabled}
                        inProgress={disabled}
                    >
                        {disabled
                            ? de.pleaseWait
                            : de.resetPassword.requestLinkButton}
                    </CircularProgressButton>
                </Box>
            </Form>
        </Formik>
    )
}

export default PasswordResetForm
