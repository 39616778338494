import { useEffect } from "react"
import de from "../data/de"

/**
 * Change the document's title that is shown in a browser's title bar or a page's tab
 * @param title
 */
const useDocumentTitle = (title: string) => {
    useEffect(() => {
        document.title = title + de.titlePostFix
    }, [title])
}

export default useDocumentTitle
