import {
    CssBaseline,
    MuiThemeProvider,
    StylesProvider
} from "@material-ui/core"
import "fontsource-roboto"
import { FC } from "react"
import CssVariableApplier from "./CssVariableApplier"
import theme from "./theme"

type Props = {}

const StyleProvider: FC<Props> = ({ children }) => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <CssVariableApplier />
            <StylesProvider injectFirst>{children}</StylesProvider>
        </MuiThemeProvider>
    )
}

export default StyleProvider
