import { Backdrop, CircularProgress } from "@material-ui/core"
import { FC, useEffect, useState } from "react"
import { SessionDurationDto } from "../../../api/account/types/SessionDurationDto"
import { UserDto } from "../../../api/user/types/UserDto"
import useClient from "../../../hooks/useClient"
import { useUserInfoDispatch } from "../../../store/userInfo/UserInfoStore"

type Props = {}

/**
 * Checks via calls to the backend if the user is currently logged in and dispatches that data to the store
 * @param props
 */
const SessionInitializer: FC<Props> = ({ children }) => {
    const userDispatch = useUserInfoDispatch()

    const client = useClient()

    const [status, setStatus] = useState<"initial" | "inProgress" | "done">(
        "initial"
    )

    useEffect(() => {
        if (status !== "initial") return

        const fetchData = async () => {
            try {
                setStatus("inProgress")
                const accountInfo = await client<UserDto>("account")

                const sessionDurationDto = await client<SessionDurationDto>(
                    "account/sessionDuration"
                )

                userDispatch({
                    type: "login",
                    payload: {
                        user: accountInfo,
                        sessionLengthSeconds:
                            sessionDurationDto.sessionLengthSeconds
                    }
                })
            } catch (err) {}
            setStatus("done")
        }

        fetchData()
    }, [client, status, userDispatch])

    if (status !== "done") {
        return (
            <Backdrop open>
                <CircularProgress />
            </Backdrop>
        )
    }

    return <>{children}</>
}

export default SessionInitializer
