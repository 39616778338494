import { TextField } from "@material-ui/core"
import { FC } from "react"
import useFormikCrmInput from "../useFormikCrmInput"

type Props = {
    name: string
    label: string
    required?: boolean
    disabled?: boolean
    autoFocus?: boolean
    type?: string
    autoComplete?: string
}

/**
 * formik text input component
 * @param props
 */
const FormTextField: FC<Props> = ({
    name,
    label,
    required,
    disabled,
    autoFocus,
    type = "text",
    autoComplete = "off"
}) => {
    const [
        field,
        ,
        isDisabled,
        showError,
        errorToDisplay
    ] = useFormikCrmInput<string>(name, disabled)

    return (
        <TextField
            {...field}
            error={showError}
            helperText={errorToDisplay}
            id={name + "-input"}
            variant="outlined"
            margin="normal"
            fullWidth
            color="secondary"
            label={label}
            type={type}
            disabled={isDisabled}
            required={required}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
        />
    )
}

export default FormTextField
