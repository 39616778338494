import { Box, Grid, Hidden, List, SwipeableDrawer } from "@material-ui/core"
import clsx from "clsx"
import { createContext, FC } from "react"
import { NavLink } from "react-router-dom"
import ApplicationLogo from "../applicationLogo/ApplicationLogo"
import "./NavigationDrawer.scss"

type Props = {
    open: boolean
    mobile: boolean
    onOpen: () => void
    onClose: () => void
}

const DrawerContext = createContext<{ onItemChosen: () => void }>({
    onItemChosen: () => {}
})

/**
 * Navigation is composed of `NavigationItem` and `NavigationCollapsible`
 */
const NavigationDrawer: FC<Props> = ({
    open,
    mobile,
    onOpen,
    onClose,
    children
}) => {
    return (
        <SwipeableDrawer
            variant={mobile ? "temporary" : "permanent"}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            className={clsx("navigation-drawer", {
                "navigation-drawer-mobile": mobile
            })}
            PaperProps={{ className: "navigation-paper" }}
        >
            <Hidden mdUp>
                <Box className="logo-container">
                    <Grid container justify="center">
                        <Box flexGrow={1} />
                        <Grid item xs>
                            <NavLink to="/admin/dashboard" onClick={onClose}>
                                <ApplicationLogo
                                    height="1.5em"
                                    className="app-logo"
                                />
                            </NavLink>
                        </Grid>
                        <Box flexGrow={1} />
                    </Grid>
                </Box>
            </Hidden>
            <DrawerContext.Provider value={{ onItemChosen: onClose }}>
                <List>{children}</List>
            </DrawerContext.Provider>
        </SwipeableDrawer>
    )
}

export default NavigationDrawer

export { DrawerContext }
