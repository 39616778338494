import { ReactNode } from "react"

const de = {
    name: "W24 Werbemanager",
    save: "Speichern",
    pageNotFound: "Seite wurde nicht gefunden",
    titlePostFix: " - W24 Werbemanager",
    email: "Email",
    password: "Passwort",
    pleaseWait: "Bitte warten...",
    dashboard: "Dashboard",
    show: "Anzeigen",
    create: "Erstellen",
    created: "Erstellt am",
    createdBy: "Erstellt von",
    lastModified: "Zuletzt bearbeitet",
    lastModifiedBy: "Zuletzt bearbeitet von",
    edit: "Bearbeiten",
    add: "Hinzufügen",
    cancel: "Abbrechen",
    close: "Schließen",
    system: "System",
    account: "Mein Konto",
    logout: "Ausloggen",
    changesApplied: "Änderungen wurden erfolgreich übernommen",
    createdTime: "Erstellungszeitpunkt",
    actions: "Aktionen",
    delete: "Löschen",
    yes: "Ja",
    no: "Nein",
    confirmationNecessary: "Bestätigung erforderlich",
    activate: "Aktivieren",
    deactivate: "Deaktivieren",
    loading: "Wird geladen...",
    notMatchFound: "keine Treffer",
    reset: "Reset",
    apply: "Übernehmen",
    error: {
        generic: "Es ist ein Fehler aufgetreten",
        genericTryAgainLater:
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
        notLoggedIn: "Sie sind nicht eingeloggt",
        loggedOut: "Sie sind nicht mehr eingeloggt",
        sessionOver: "Session abgelaufen",
        notFound: "Eintrag nicht gefunden",
        notAllowed: "Aktion ist nicht erlaubt",
        forbidden: "Sie besitzen nicht die nötige Berechtigung"
    },
    form: {
        validationError: {
            required: "Pflichtfeld",
            emailInvalid: "Keine gültige Emailadresse",
            maxCharacterLimit: (max: number) =>
                `Das Feld darf nicht länger als ${max} Zeichen sein`,
            minCharacterLimit: (max: number) =>
                `Das Feld maximal ${max} Zeichen lang sein`,
            atLeastOneLower: "Muss mindestens einen Kleinbuchstaben beinhalten",
            atLeastOneUpper: "Muss mindestens einen Großbuchstaben beinhalten",
            atLeastOneNumber: "Mindestens eine Nummer muss vorkommen",
            validVatNumber: "Keine gültige ATU-Nummer",
            invalidDate: "Datum hat ungültiges Format"
        },
        crop: {
            title: "Bildbereich auswählen",
            submit: "Auswahl bestätigen"
        }
    },
    session: {
        almostOver: "Sie werden wegen Inaktivität ausgeloggt",
        stillHere: "Bin eh noch da!",
        over: "Session abgelaufen",
        loggedOut: "Sie sind nicht mehr eingeloggt"
    },
    login: {
        title: "Login",
        prompt: "Bitte geben Sie ihre Anmeldedaten ein",
        forgotPassword: "Passwort vergessen?",
        showPassword: "Passwort anzeigen",
        hidePassword: "Passwort verstecken",
        wrongUserDetails:
            "Email oder Passwort sind nicht richtig! Bitte überprüfen Sie ihre Angaben!",
        generalLoginError:
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
    },
    resetPassword: {
        title: "Passwort zurücksetzen",
        instruction:
            "Geben Sie ihre Emailadresse ein und Sie erhalten per Mail einen Link zum Zurücksetzen Ihres Passworts.",
        requestLinkButton: "Link anfordern",
        emailSent: "Email wurde versendet",
        alreadyLoggedIn:
            "Sie können kein Passwort für einen fremden Account setzen, wenn sie bereits eingeloggt sind",
        userNotActive: "Der Benutzer ist nicht aktiviert",
        invalidTicket: "Link nicht korrekt",
        expiredTicket: "Link ist abgelaufen",
        newPasswordSuccess: "Das Passwort erfolgreich gesetzt",
        notMatching: "Passwörter stimmen nicht überein",
        passwordConfirmation: "Passwort wiederholen"
    },
    createPassword: {
        title: "Passwort setzen",
        requirementsInfo: "Passwort muss folgende Bedingungen erfüllen:",
        characterAmount: (min: number, max: number) =>
            `Zwischen ${min} und ${max} Zeichen`,
        atLeastOneUpper: "Mindestens 1 Großbuchstabe",
        atLeastOneLower: "Mindestens 1 Kleinbuchstabe",
        atLeastOneNumber: "Mindestens 1 Ziffer",
        successful: "Das Konto wurde erfolgreich aktiviert"
    },
    pagination: {
        rowsPerPage: "Pro Seite",
        displayedRows: (from: number, to: number, count: number) =>
            `${from}-${to} von ${count}`
    },
    gender: {
        female: "Weiblich",
        male: "Männlich",
        diverse: "Divers",
        notStated: "Nicht angegeben"
    },
    comment: {
        title: "Kommentare",
        titleSingular: "Kommentar",
        noComments: "Keine Kommentare vorhanden",
        addedComment: "Kommentar hinzugefügt"
    },
    user: {
        title: "Benutzer",
        userData: "Benutzerdaten",
        titlePrefix: "Titel Vorstehend",
        titleSuffix: "Titel Nachstehend",
        firstname: "Vorname",
        lastname: "Nachname",
        email: "Email",
        profilePicture: "Profilbild",
        setPicture: "Bild setzen",
        pictureError: "Bild konnte nicht hochgeladen werden",
        removePicture: "Bild entfernen",
        lastLogin: "Zuletzt Eingeloggt",
        neverLoggedIn: "Noch nie",
        successDeleteUser: "Erfolgreich entfernt",
        deletingOwnAccountWarning: "Eigener Account kann nicht gelöscht werden",
        confirmDelete:
            "Sind Sie sicher, dass Sie diese Person aus dem System löschen möchten? Die Daten der Person werden entfernt.",
        status: {
            title: "Status",
            active: "Aktiv",
            inactive: "Inaktiv",
            notInitialized: "Nicht Initialisiert",
            successfullyChanged: "Status erfolgreich geändert",
            infoNotIntialized:
                "Wenn Sie auf diesen Button klicken wird der Benutzer per Mail nochmals dazu aufgefordert sich zu aktivieren.",
            activationMailSent: "Aktivierungsmail versendet",
            activationMail: "Aktivierungsmail",
            confirmActivation:
                "Sind Sie sicher, dass Sie diese Person wieder aktivieren möchten?",
            confirmDeactivation:
                "Sind Sie sicher, dass Sie diese Person deaktivieren möchten?",
            confirmSendEmail:
                "Sind Sie sicher, dass Sie die Aktivierungsmail nochmals versenden möchten?",
            infoInactiveUser:
                "Deaktivierte BenutzerInnen können sich nicht mehr einloggen",
            onlyAdmins:
                "Nur Admin-User dürfen den Status von anderen Usern bearbeiten."
        }
    },
    userEdit: {
        myProfile: "Mein Konto"
    },
    addUser: {
        documentTitle: "Benutzer erstellen",
        title: "Erstellen",
        infoVerificationMail: (
            <>
                Damit sich die Person in das System einloggen kann, muss diese
                zuerst die Registrierung abschließen.
                <br />
                Informationen dazu werden nach dem Speichern an die angegebene
                Emailadresse gesendet.
            </>
        ),
        successAddUser: "Erfolgreich erstellt",
        emailAlreadyUsed: "Email bereits vorhanden"
    },
    company: {
        title: "Firmen",
        companyData: "Firmendaten",
        email: "Email",
        name: "Name",
        website: "Website",
        address: "Adresse",
        codeZip: "PLZ",
        phoneNumber: "Telefon",
        city: "Ort",
        vatNumber: "ATU-Nummer",
        successDelete: "Erfolgreich entfernt",
        confirmDelete:
            "Sind Sie sicher, dass Sie diese Firma aus dem System löschen möchten? Die Daten der Firma werden entfernt."
    },
    addCompany: {
        documentTitle: "Firma erstellen",
        title: "Erstellen",
        successAddCompany: "Erfolgreich erstellt"
    },
    contactPerson: {
        title: "Kontaktpersonen",
        firstname: "Vorname",
        lastname: "Nachname",
        email: "Email",
        phoneNumber: "Telefon",
        gender: "Geschlecht",
        successDelete: "Erfolgreich entfernt"
    },
    addContactPerson: {
        title: "Kontakt erstellen",
        successAddContactPerson: "Erfolgreich erstellt"
    },
    order: {
        title: "Aufträge",
        orderData: "Auftragsdaten",
        companyName: "Firma",
        packageName: "Paket",
        link: "Link",
        graphic: "Werbemittel",
        showImage: "Werbemittel anzeigen",
        comment: "Anmerkung",
        paymentInformation: "Zahlungsinformationen",
        showPaymentInformation: "Zahlungsinformationen anzeigen",
        playouts: "Ausspielungen",
        price: "Preis",
        successDelete: "Erfolgreich entfernt",
        confirmDelete:
            "Sind Sie sicher, dass Sie diesen Auftrag aus dem System löschen möchten? Die Daten des Auftrags werden entfernt.",
        noPlayouts: "Keine zugehörigen Ausspielungen",
        invoice: "Rechnung",
        status: {
            title: "Status",
            notApproved: "Prüfung Agentur",
            needsAction: "Aufgabe Vertrieb",
            approved: "Genehmigt",
            cancelled: "Storniert",
            approve: "Freigeben",
            check: "Prüfung erforderlich",
            cancel: "Stornieren",
            successfullyChanged: "Status erfolgreich geändert",
            messageCancelOrder:
                "Auftrag abbrechen. Eventuell angelegte Werbeschaltungen werden entfernt.",
            messageApproveOrder:
                "Auftrag wird freigegeben und es kann der Zeitraum der Werbeaustrahlung definiert werden. ",
            messageNotFilename:
                "Auftrag kann nur freigegeben werden, wenn ein Werbemittel zum Auftrag hinzugefügt wurde.",
            messageNeedActionOrder:
                "Auftrag benötigt Input durch den/die AuftraggeberIn.",
            messageCheckOrder:
                "Der Auftrag wird für nochmalige Überprüfung markiert.",
            messageCancelledOrder: "Der Auftrag wurde storniert."
        },
        paymentStatus: {
            title: "Bezahlstatus",
            SUCCESS: "Zahlung eingegangen",
            IN_PROGRESS: "Wird durchgeführt",
            PENDING: "Zahlung ausstehend",
            FAILURE: "Zahlung fehlgeschlagen",
            CANCEL: "Durch UserIn abgebrochen",
            MANUAL: "Manuell angelegt"
        },
        orderImage: {
            uploadGrafic: "Werbemittel hochladen",
            removeGrafic: "Werbemittel entfernen",
            errorWrongDimensions: "Bild muss 800x800px groß sein"
        },
        packages: {
            WEEK: "WerbeWOCHE",
            MONTH: "WerbeMONAT"
        }
    },
    addOrder: {
        documentTitle: "Auftrag erstellen",
        title: "Erstellen",
        successAddOrder: "Erfolgreich erstellt"
    },
    playout: {
        title: "Werbeausspielungen",
        titleSingular: "Werbeausspielung",
        id: "ID",
        dateFrom: "Datum von",
        dateTo: "Datum bis",
        altText: "Alt-Text",
        playoutPriority: "Priorität",
        order: "Zugehörige Bestellung",
        company: "Kunde",
        selfPromotion: "Eigenpromo",
        successDelete: "Erfolgreich entfernt",
        confirmDelete:
            "Sind Sie sicher, dass Sie diese Ausspielung aus dem System entfernen möchten?",
        noOrder: "Keiner Bestellung zugeordnet",
        filterByDay: "Nach Tag filtern"
    },
    addPlayout: {
        title: "Playout hinzufügen",
        cardTitle: "Playout manuell hinzufügen",
        successAdd: "Manuelles Playout hinzugefügt",
        dateOrderError: "Datum-von muss vor Datum-bis liegen",
        dayPlayoutLimitError: (dailyLimit: ReactNode) => (
            <>
                Eintrag kann nicht gespeichert werden.
                <br />
                An folgenden Tagen würde das tägliche Limit von {dailyLimit}
                &nbsp;Ausspielungen mit Fremdwerbung pro Tag überschritten
                werden:
            </>
        )
    },
    daySelector: {
        selectDay: "Tag auswählen",
        jumpBack: "1 Woche zurück",
        jumpForward: "1 Woche vor",
        manualDateSelection: "Zu Datum springen"
    },
    file: {
        sucessUploadFile: `File wurde erfolgreich hochgeladen`,
        uploadFileSizeLimit: (number: number) =>
            `Die Datei darf nur maximal ${number}MB groß sein`
    },
    about: {
        title: "Über",
        buildTime: "Buildzeitpunkt"
    }
}

export default de
