import { fade, useTheme } from "@material-ui/core"
import { FC, useEffect } from "react"

/**
 * Makes theme properties availabe via css properties.
 * If you want to use a property of the material-ui theme in a stylesheet,
 * make it available via `set()` in the effect.
 */
const CssVariableApplier: FC<{}> = () => {
    const theme = useTheme()

    useEffect(() => {
        const set = (key: string, value: string) => {
            document.documentElement.style.setProperty(key, value)
        }

        //colors
        set("--theme-palette-primary-main", theme.palette.primary.main)
        set(
            "--theme-palette-primary-main-contrast-text",
            theme.palette.getContrastText(theme.palette.primary.main)
        )

        set("--theme-palette-secondary-main", theme.palette.secondary.main)
        set(
            "--theme-palette-secondary-main-contrast-text",
            theme.palette.getContrastText(theme.palette.secondary.main)
        )

        set("--theme-palette-text-secondary", theme.palette.text.secondary)

        set("--theme-palette-error-main", theme.palette.error.main)
        set("--theme-palette-success-main", theme.palette.success.main)

        set(
            "--theme-palette-background-paper-contrast-text",
            theme.palette.getContrastText(theme.palette.background.paper)
        )

        set("--theme-palette-background-paper", theme.palette.background.paper)

        set(
            "--theme-palette-background-default",
            theme.palette.background.default
        )
        set(
            "--theme-palette-background-default-contrast-text",
            theme.palette.getContrastText(theme.palette.background.default)
        )

        set("--theme-palette-action-disabled", theme.palette.action.disabled)

        set("--theme-palette-error-light", theme.palette.error.light)

        //colors with opacity
        set(
            "--theme-palette-secondary-main-opacity-50",
            fade(theme.palette.secondary.main, 0.5)
        )
        set(
            "--theme-palette-primary-main-opacity-50",
            fade(theme.palette.primary.main, 0.5)
        )

        set(
            "--theme-palette-secondary-main-opacity-10",
            fade(theme.palette.secondary.main, 0.1)
        )

        set(
            "--theme-palette-error-light-opacity-20",
            fade(theme.palette.error.light, 0.2)
        )

        //border radius
        set("--theme-shape-borderRadius", `${theme.shape.borderRadius}px`)

        //height of the appbar
        set(
            "--theme-mixins-toolbar-minHeight",
            `${theme.mixins.toolbar.minHeight}px`
        )

        //zIndex
        set("--theme-zIndex-drawer", `${theme.zIndex.drawer + 1}`)

        //spacing
        for (let i = 0; i <= 10; i++) {
            set(`--theme-spacing-${i}`, `${theme.spacing(i)}px`)
        }
    }, [theme])

    return null
}

export default CssVariableApplier
