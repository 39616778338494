import * as Yup from "yup"
import de from "../../data/de"

const atLeastOneLowerPattern = /[a-zöäü]/
const atLeastOneUpperPattern = /[A-ZÖÄÜ]/
const atLeastOneNumberPattern = /\d/

const passwordValidation = Yup.string()
    .required(de.form.validationError.required)
    .min(8, ({ min }) => de.form.validationError.minCharacterLimit(min))
    .max(64, ({ max }) => de.form.validationError.maxCharacterLimit(max))
    .test(
        "at-least-one-lower-case",
        de.form.validationError.atLeastOneLower,
        (value) => atLeastOneLowerPattern.test(value + "")
    )
    .test(
        "at-least-one-upper-case",
        de.form.validationError.atLeastOneUpper,
        (value) => atLeastOneUpperPattern.test(value + "")
    )
    .test(
        "at-least-one-number",
        de.form.validationError.atLeastOneNumber,
        (value) => atLeastOneNumberPattern.test(value + "")
    )

export default passwordValidation

export {
    atLeastOneLowerPattern,
    atLeastOneUpperPattern,
    atLeastOneNumberPattern
}
