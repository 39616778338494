import { useSnackbar } from "notistack"
import { useHistory } from "react-router"
import de from "../../data/de"
import client from "../../functions/client"
import useHandleError from "../../hooks/useHandleError"
import { AccountActivationDto } from "./types/AccountActivationDto"

const useActivation = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [handleError] = useHandleError()

    const activate = async (values: AccountActivationDto) => {
        try {
            await client(
                `${process.env.REACT_APP_API_URL}/account/activation`,
                {
                    body: values,
                    method: "POST"
                }
            )
            enqueueSnackbar(de.createPassword.successful, {
                variant: "success"
            })

            history.push("/login")
        } catch (err) {
            handleError(err)
            return Promise.reject(err)
        }
    }

    return activate
}

export default useActivation
