import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { FC, ReactNode, useContext, useEffect } from "react"
import { Link as RouterLink, useRouteMatch } from "react-router-dom"
import { CollapsibleContext } from "./NavigationCollapsible"
import { DrawerContext } from "./NavigationDrawer"

type Props = {
    text?: string
    to: string
    icon?: ReactNode
}

const NavigationItem: FC<Props> = ({ text = "", to, icon }) => {
    const match = useRouteMatch(to)
    const isExact = match?.isExact

    const { onChildSelected } = useContext(CollapsibleContext)
    const { onItemChosen } = useContext(DrawerContext)

    useEffect(() => {
        if (isExact) onChildSelected()
    }, [isExact, onChildSelected])

    return (
        <ListItem
            button
            component={RouterLink}
            to={to}
            selected={isExact}
            onClick={onItemChosen}
        >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} />
        </ListItem>
    )
}

export default NavigationItem
