import { Typography } from "@material-ui/core"
import clsx from "clsx"
import { useField } from "formik"
import { FC } from "react"
import de from "../../data/de"
import {
    atLeastOneLowerPattern,
    atLeastOneNumberPattern,
    atLeastOneUpperPattern
} from "../../functions/validation/passwordValidation"
import "./PasswordRequirements.scss"

/**
 * Only for use in `PasswordCreateForm.tsx`.
 * Displays a password-requirements checklist for the user.
 */
const PasswordRequirements: FC<{}> = () => {
    const [field] = useField<string>("password")

    const minLength = 8
    const maxLength = 64

    const length =
        !!field.value &&
        field.value.length >= minLength &&
        field.value.length <= maxLength
    const atLeastOneUpper = atLeastOneUpperPattern.test(field.value)
    const atLeastOneLower = atLeastOneLowerPattern.test(field.value)
    const atLeastOneNumber = atLeastOneNumberPattern.test(field.value)

    const getClassnames = (requirementOk: boolean) => {
        if (field.value.trim() === "") return ""
        return clsx({ ok: requirementOk, nok: !requirementOk })
    }

    return (
        <Typography component="div" className="password-requirements">
            {de.createPassword.requirementsInfo}
            <ul>
                <li className={getClassnames(length)}>
                    {de.createPassword.characterAmount(minLength, maxLength)}
                </li>
                <li className={getClassnames(atLeastOneUpper)}>
                    {de.createPassword.atLeastOneUpper}
                </li>
                <li className={getClassnames(atLeastOneLower)}>
                    {de.createPassword.atLeastOneLower}
                </li>
                <li className={getClassnames(atLeastOneNumber)}>
                    {de.createPassword.atLeastOneNumber}
                </li>
            </ul>
        </Typography>
    )
}

export default PasswordRequirements
