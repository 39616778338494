import { Grid, useTheme } from "@material-ui/core"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import CircularProgressButton from "../../../components/circularProgressButton/CircularProgressButton"
import FormPasswordField from "../../../components/inputs/form/FormPasswordField"
import FormTextField from "../../../components/inputs/form/FormTextField"
import MaterialRouterLink from "../../../components/materialRouterLink/MaterialRouterLink"
import de from "../../../data/de"
import emailValidation from "../../../functions/validation/emailValidation"

export type LoginFormValues = {
    email: string
    password: string
}

type Props = {
    onSubmit: (values: LoginFormValues) => Promise<void>
    disabled?: boolean
}

const validationSchema = Yup.object().shape({
    email: emailValidation,
    password: Yup.string().required(de.form.validationError.required)
})

export const LoginForm: FC<Props> = ({ onSubmit, disabled }) => {
    const theme = useTheme()

    const initialValues: LoginFormValues = {
        email: "",
        password: ""
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, helpers) => {
                helpers.setStatus({ disabled: true })
                onSubmit(values).catch(() =>
                    helpers.setStatus({ disabled: false })
                )
            }}
        >
            <Form className="form" noValidate>
                <FormTextField
                    label={de.email}
                    name="email"
                    type="email"
                    required
                    disabled={disabled}
                    autoComplete="email"
                    autoFocus
                />
                <FormPasswordField
                    label={de.password}
                    name="password"
                    required
                    disabled={disabled}
                />
                <CircularProgressButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={"submit"}
                    disabled={disabled}
                    inProgress={disabled}
                    style={{ marginBottom: theme.spacing(2) }}
                >
                    {disabled ? de.pleaseWait : de.login.title}
                </CircularProgressButton>
                <Grid container>
                    <Grid item xs>
                        <MaterialRouterLink to="/resetPassword">
                            {de.login.forgotPassword}
                        </MaterialRouterLink>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
