import { FC, useState } from "react"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import de from "../../data/de"
import GuestCard from "../../components/guestCard/GuestCard"
import PasswordResetForm, {
    PasswordResetFormValues
} from "./components/PasswordResetForm"
import useResetPassword from "../../api/account/useResetPassword"
import { Typography } from "@material-ui/core"

type Props = {}

const ResetPassword: FC<Props> = (props) => {
    useDocumentTitle(de.resetPassword.title)
    const [disabled, setDisabled] = useState(false)
    const resetPassword = useResetPassword()
    const [error, setError] = useState("")

    const onSubmit = (values: PasswordResetFormValues) => {
        setDisabled(true)
        return resetPassword(values.email).catch((err) => {
            setDisabled(false)
            if (err.errorCode === "ALREADY_LOGGED_IN")
                setError(de.resetPassword.alreadyLoggedIn)
            else if (err.errorCode === "USER_NOT_ACTIVE")
                setError(de.resetPassword.userNotActive)
            else setError(de.error.genericTryAgainLater)
        })
    }

    return (
        <GuestCard
            title={de.resetPassword.title}
            description={de.resetPassword.instruction}
        >
            {error && (
                <Typography component="h2" variant="subtitle1" color="error">
                    {error}
                </Typography>
            )}
            <PasswordResetForm disabled={disabled} onSubmit={onSubmit} />
        </GuestCard>
    )
}

export default ResetPassword
