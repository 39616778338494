import { createMuiTheme, ThemeOptions } from "@material-ui/core"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

const breakpoints = createBreakpoints({})

const theme: ThemeOptions = {
    palette: {
        primary: {
            main: "#C4161D"
        },
        secondary: {
            main: "#000000"
        }
    },
    typography: {
        fontWeightRegular: 500,
        fontSize: 13,
        h4: {
            fontSize: "1.6rem",
            [breakpoints.down("xs")]: {
                fontSize: "1.4rem"
            }
        },
        h5: {
            fontSize: "1.2rem",
            [breakpoints.down("xs")]: {
                fontSize: "1.1rem"
            }
        }
    },
    overrides: {
        MuiTableCell: {
            root: {
                whiteSpace: "nowrap"
            }
        }
    }
}

export default createMuiTheme(theme)
