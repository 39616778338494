import { useCallback } from "react"

const useSessionStorage = <S>(key: string, initialValue: S) => {
    const getItem = useCallback(() => {
        const stored = sessionStorage.getItem(key)
        let ret = initialValue
        if (stored !== null) {
            try {
                ret = JSON.parse(stored)
            } catch (err) {
                console.error("error parsing item from sessionStorage", err)
            }
        }
        return ret
    }, [initialValue, key])

    const setItem = useCallback(
        (toStore: S) => {
            sessionStorage.setItem(key, JSON.stringify(toStore))
        },
        [key]
    )

    const clear = useCallback(() => sessionStorage.removeItem(key), [key])

    return [getItem, setItem, clear] as const
}

export default useSessionStorage
