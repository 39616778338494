import { useMediaQuery, useTheme } from "@material-ui/core"
import { FC, useState } from "react"
import AdminNavigation from "../navigation/AdminNavigation"
import NavigationDrawer from "../navigation/NavigationDrawer"
import CrmAppBar from "./CrmAppBar"

type Props = {}

const AuthenticatedContainer: FC<Props> = ({ children }) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <>
            <CrmAppBar onMenuOpen={() => setOpen(true)} mobile={mdDown} />
            <NavigationDrawer
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                mobile={mdDown}
                open={open}
            >
                <AdminNavigation />
            </NavigationDrawer>
            {children}
        </>
    )
}

export default AuthenticatedContainer
