import { Button } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { FC, ReactText, useEffect, useState } from "react"
import Countdown from "../../../components/countdown/Countdown"
import de from "../../../data/de"
import useLogout from "../../../api/account/useLogout"
import useClient from "../../../hooks/useClient"
import { useUserInfoState } from "../../../store/userInfo/UserInfoStore"

type Props = {
    /**
     * time in seconds before the warning appears that the session is ending
     * can be set via the spring property "server.servlet.session.timeout"
     */
    secondsBeforeWarning: number
}

const calcDateDiff = (date: Date | null) =>
    (date?.getTime() ?? new Date().getTime()) - new Date().getTime()

/**
 * don't mount this component if there is no active session
 * @param props
 */
const SessionExpirationWarning: FC<Props> = ({
    secondsBeforeWarning,
    children
}) => {
    const userInfo = useUserInfoState()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const logout = useLogout(true)
    const [snackBar, setSnackbar] = useState<ReactText | null>(null)
    const client = useClient()

    /**
     * logs the user out if he is inactive until the session runs out
     */
    useEffect(() => {
        let timeoutLogout: ReturnType<typeof setTimeout>
        const handleSessionEnd = () => {
            logout()
            enqueueSnackbar(de.session.over, {
                variant: "warning",
                disableWindowBlurListener: false,
                autoHideDuration: 5000
            })
        }

        if (userInfo.sessionEnd) {
            const timeDifferenceUntilLogout = calcDateDiff(userInfo.sessionEnd)

            timeoutLogout = setTimeout(
                handleSessionEnd,
                timeDifferenceUntilLogout
            )
        }

        return () => {
            clearTimeout(timeoutLogout)
        }
    }, [enqueueSnackbar, logout, userInfo.sessionEnd])

    /**
     * shows a warning to the user secondsBeforeWarning seconds before the session actually runs out
     * contains a button that makes a request to actuator/info ro refresh the session
     */
    useEffect(() => {
        let timeoutWarning: ReturnType<typeof setTimeout>

        const handleRefresh = () => {
            client("actuator/info")
        }

        const showCountdownWarning = (time: number) => {
            if (snackBar) return

            const newSnackbar = enqueueSnackbar(
                <>
                    {de.session.almostOver}&nbsp;
                    <b>
                        <Countdown intitialTime={Math.floor(time / 1000)} />
                    </b>
                </>,
                {
                    variant: "default",
                    autoHideDuration: time,
                    disableWindowBlurListener: true,
                    action: (
                        <Button color="primary" onClick={handleRefresh}>
                            {de.session.stillHere}
                        </Button>
                    )
                }
            )

            setSnackbar(newSnackbar)
        }

        if (userInfo.sessionEnd) {
            const timeDiff = calcDateDiff(userInfo.sessionEnd)

            const timeUntilSnackBarWarning = Math.max(
                0,
                timeDiff - secondsBeforeWarning * 1000
            )

            if (timeUntilSnackBarWarning > 0) {
                if (snackBar) {
                    closeSnackbar(snackBar)
                    setSnackbar(null)
                }

                timeoutWarning = setTimeout(
                    () =>
                        showCountdownWarning(calcDateDiff(userInfo.sessionEnd)),
                    timeUntilSnackBarWarning
                )
            }
        }

        return () => {
            clearTimeout(timeoutWarning)
        }
    }, [
        client,
        closeSnackbar,
        enqueueSnackbar,
        secondsBeforeWarning,
        snackBar,
        userInfo.sessionEnd
    ])

    return <>{children}</>
}

export default SessionExpirationWarning
