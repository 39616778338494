import { Link } from "@material-ui/core"
import { FC } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

type Props = {
    to: string
    notClickable?: boolean
    underlineDisabled?: boolean
}

/**
 * Material-UI styled react-router Link
 */
const MaterialRouterLink: FC<Props> = ({
    to,
    notClickable,
    children,
    underlineDisabled
}) => {
    if (notClickable) return <>{children}</>

    return (
        <Link
            component={ReactRouterLink}
            to={to}
            underline={underlineDisabled ? "none" : undefined}
        >
            {children}
        </Link>
    )
}

export default MaterialRouterLink
