import {
    Avatar,
    Button,
    Hidden,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core"
import { FC, MouseEvent, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import de from "../../data/de"
import generateUserSalutation from "../../functions/generateUserSalutation"
import useLogout from "../../api/account/useLogout"
import { useUserInfoState } from "../../store/userInfo/UserInfoStore"

type Props = {}

const ProfileMenu: FC<Props> = () => {
    const userInfo = useUserInfoState()
    const [anchorElement, setAnchorElement] = useState<Element | null>(null)
    const logout = useLogout()

    if (!userInfo.loggedInUser) return null

    const fullName = generateUserSalutation(
        userInfo.loggedInUser.firstname,
        userInfo.loggedInUser.lastname,
        userInfo.loggedInUser.email
    )

    const handleOpen = (e: MouseEvent) => {
        setAnchorElement(e.currentTarget)
    }

    const closeMenu = () => setAnchorElement(null)

    const handleProfile = () => {
        closeMenu()
    }

    const handleLogout = () => {
        logout()
    }

    return (
        <>
            <Button onClick={handleOpen} color="inherit">
                <Avatar
                    alt={fullName}
                    src={userInfo.loggedInUser.urlProfilePicture}
                />
                <Hidden smDown>
                    &nbsp; &nbsp;
                    <Typography
                        color="inherit"
                        style={{ textTransform: "none" }}
                    >
                        {fullName}
                    </Typography>
                </Hidden>
            </Button>
            <Menu
                getContentAnchorEl={null}
                anchorEl={anchorElement}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                open={!!anchorElement}
                onClose={closeMenu}
            >
                <MenuItem
                    component={RouterLink}
                    to="/admin/profile"
                    onClick={handleProfile}
                >
                    {de.account}
                </MenuItem>
                <MenuItem onClick={handleLogout}>{de.logout}</MenuItem>
            </Menu>
        </>
    )
}

export default ProfileMenu
