import { createContext, Dispatch, FC, useContext, useReducer } from "react"

/**
 * creates state-hook, dispatch-hook and a context provider that implement the state reducer pattern
 * @param reducer reducer function that creates the new state from the old state and an action
 * @param initialState state that is returned if this store isn't localstorage enabled or hasn't been initialized
 * @param localStorageKey if set data is persisted in the local storage and retrieved when application starts
 * @param deserialize if set overrides deserialization function
 * @param serialize if set overrides serialization function
 */
const makeStore = <S, A>(
    reducer: (state: S, action: A) => S,
    initialState: S,
    localStorageKey?: string,
    deserialize?: (storedItem: string) => S,
    serialize?: (state: S) => string
) => {
    const StoreContext = createContext<S | undefined>(undefined)
    const DispatchContext = createContext<Dispatch<A> | undefined>(undefined)

    const StoreProvider: FC<{}> = ({ children }) => {
        const getInitialState = () => {
            if (!!localStorageKey) {
                try {
                    const item = localStorage.getItem(localStorageKey)
                    if (item !== null) {
                        if (!!deserialize) return deserialize(item)
                        return JSON.parse(item)
                    }
                } catch (err) {
                    console.error(err)
                }
            }

            return initialState
        }

        const reducerWrapper: (state: S, action: A) => S = (state, action) => {
            const newState = reducer(state, action)

            if (!!localStorageKey) {
                if (!!serialize)
                    localStorage.setItem(localStorageKey, serialize(newState))
                else
                    localStorage.setItem(
                        localStorageKey,
                        JSON.stringify(newState)
                    )
            }

            return newState
        }

        const [store, dispatch] = useReducer(
            reducerWrapper,
            null,
            getInitialState
        )

        if (!StoreContext || !DispatchContext) return null

        return (
            <StoreContext.Provider value={store}>
                <DispatchContext.Provider value={dispatch}>
                    {children}
                </DispatchContext.Provider>
            </StoreContext.Provider>
        )
    }

    const useStore = () => {
        const context = useContext(StoreContext)
        if (!context) throw new Error("useStore must be used within a Provider")
        return context
    }

    const useDispatch = () => {
        const context = useContext(DispatchContext)
        if (!context) throw new Error("useStore must be used within a Provider")
        return context
    }

    if (localStorageKey)
        StoreProvider.displayName = `${localStorageKey}.Provider`

    return [StoreProvider, useStore, useDispatch] as const
}

export default makeStore
