import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import client from "../../functions/client"
import { useUserInfoDispatch } from "../../store/userInfo/UserInfoStore"

const useLogout = (skipLogoutApiCall = false) => {
    const userInfoDispatch = useUserInfoDispatch()
    const history = useHistory()

    const logout = useCallback(async () => {
        if (!skipLogoutApiCall)
            await client(`${process.env.REACT_APP_CONTEXT_PATH}/logout`, {
                method: "POST",
                body: new FormData()
            })

        userInfoDispatch({
            type: "logout"
        })

        history.push("/login")
    }, [history, skipLogoutApiCall, userInfoDispatch])

    return logout
}

export default useLogout
