import { useRef } from "react"
import client, { ClientConfig } from "../functions/client"
import { useUserInfoDispatch } from "../store/userInfo/UserInfoStore"

const useClient = () => {
    const userInfoDispatch = useUserInfoDispatch()

    const clientRef = useRef(
        <T>(relativeUrl: string, config: ClientConfig = {}) => {
            return client<T>(
                `${process.env.REACT_APP_API_URL}/${relativeUrl}`,
                config
            ).then((result) => {
                userInfoDispatch({ type: "refresh" })
                return result
            })
        }
    )

    return clientRef.current
}

export default useClient
