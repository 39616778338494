import { SnackbarProvider } from "notistack"
import { FC } from "react"

type Props = {}

const W24SnackbarProvider: FC<Props> = ({ children }) => {
    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3500} dense>
            {children}
        </SnackbarProvider>
    )
}

export default W24SnackbarProvider
