import { useHistory } from "react-router-dom"
import client from "../../functions/client"
import decodePunycode from "../../functions/decodePunycode"
import { useUserInfoDispatch } from "../../store/userInfo/UserInfoStore"
import useSessionStorage from "../../hooks/useSessionStorage"
import { UserDto } from "../user/types/UserDto"
import { SessionDurationDto } from "./types/SessionDurationDto"

const useLogin = () => {
    const userInfoDispatch = useUserInfoDispatch()
    const history = useHistory()
    const [getLastPath] = useSessionStorage("lastLoggedInPath", "/")

    const login = async (username: string, password: string) => {
        const formData = new FormData()

        formData.append("username", decodePunycode(username))
        formData.append("password", password)

        try {
            const response = await client<SessionDurationDto>(
                `${process.env.REACT_APP_CONTEXT_PATH}/perform_login`,
                {
                    body: formData,
                    method: "POST"
                }
            )

            const userInfo = await client<UserDto>(
                `${process.env.REACT_APP_API_URL}/account`
            )

            userInfoDispatch({
                type: "login",
                payload: {
                    user: userInfo,
                    sessionLengthSeconds: response.sessionLengthSeconds
                }
            })

            history.push(getLastPath())
        } catch (err) {
            return Promise.reject(err)
        }
    }

    return login
}

export default useLogin
