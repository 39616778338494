import { useSnackbar } from "notistack"
import { useHistory } from "react-router"
import de from "../../data/de"
import client from "../../functions/client"
import decodePunycode from "../../functions/decodePunycode"
import useHandleError from "../../hooks/useHandleError"

const useResetPassword = () => {
    const [handleError] = useHandleError()
    const { enqueueSnackbar } = useSnackbar()
    const history = useHistory()

    const useResetPassword = async (email: string) => {
        email = decodePunycode(email)
        try {
            await client(
                `${process.env.REACT_APP_API_URL}/account/${email}/passwordReset`,
                {
                    method: "POST"
                }
            )

            enqueueSnackbar(de.resetPassword.emailSent, {
                variant: "success"
            })

            history.push("/login")
        } catch (err) {
            handleError(err)
            return Promise.reject(err)
        }
    }

    return useResetPassword
}

export default useResetPassword
