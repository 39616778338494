import { FC } from "react"
import GlobalWrapper from "./context/GlobalWrapper"
import Routes from "./context/routing/Routes"

type Props = {}

const App: FC<Props> = (props) => {
    return (
        <GlobalWrapper>
            <Routes />
        </GlobalWrapper>
    )
}

export default App
