import { Box, Typography } from "@material-ui/core"
import { FC, useState } from "react"
import { Redirect } from "react-router"
import GuestCard from "../../components/guestCard/GuestCard"
import de from "../../data/de"
import useLogin from "../../api/account/useLogin"
import { isApiError } from "../../api/types/ApiError"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import { useUserInfoState } from "../../store/userInfo/UserInfoStore"
import { LoginForm, LoginFormValues } from "./components/LoginForm"

type Props = {}

const Login: FC<Props> = (props) => {
    useDocumentTitle(de.login.title)
    const userInfo = useUserInfoState()
    const login = useLogin()
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState<null | "userDetails" | "general">(null)

    const onSubmit = (values: LoginFormValues) => {
        setDisabled(true)
        return login(values.email, values.password).catch((err) => {
            setDisabled(false)
            if (isApiError(err) && err.httpStatus === "UNAUTHORIZED")
                setError("userDetails")
            else setError("general")

            return Promise.reject(err)
        })
    }

    if (userInfo.loggedInUser) return <Redirect to="/admin" />

    return (
        <GuestCard
            title={`${de.name} - ${de.login.title}`}
            description={de.login.prompt}
        >
            <Box>
                {error && (
                    <Typography
                        component="h2"
                        variant="subtitle1"
                        color="error"
                    >
                        {error === "userDetails"
                            ? de.login.wrongUserDetails
                            : de.error.genericTryAgainLater}
                    </Typography>
                )}
                <LoginForm onSubmit={onSubmit} disabled={disabled} />
            </Box>
        </GuestCard>
    )
}

export default Login
