import { Button, CircularProgress } from "@material-ui/core"
import { ComponentProps, FC } from "react"

type Props = ComponentProps<typeof Button> & {
    inProgress?: boolean
}

/**
 * regular material-ui Button with a controllable CircularProgress on the right side of the content
 * @param props
 */
const CircularProgressButton: FC<Props> = ({
    inProgress,
    children,
    ...restProps
}) => {
    return (
        <Button {...restProps}>
            {children}
            {inProgress && (
                <>
                    &nbsp;
                    <CircularProgress size={20} />
                </>
            )}
        </Button>
    )
}

export default CircularProgressButton
