import { AppBar, Box, Toolbar, Typography } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { FC } from "react"
import ApplicationLogo from "../applicationLogo/ApplicationLogo"
import ProfileMenu from "../profileMenu/ProfileMenu"
import MaterialRouterLink from "../materialRouterLink/MaterialRouterLink"
import "./CrmAppBar.scss"

type Props = {
    onMenuOpen: () => void
    mobile?: boolean
}

const CrmAppBar: FC<Props> = ({ onMenuOpen, mobile }) => {
    return (
        <AppBar
            position="fixed"
            classes={{
                root: "root-crm-app-bar"
            }}
        >
            <Toolbar className="root-toolbar">
                {mobile ? (
                    <MenuIcon onClick={onMenuOpen} className="size-icon" />
                ) : (
                    <Box>
                        <MaterialRouterLink to="/admin/dashboard">
                            <Typography>
                                <ApplicationLogo className="logo" />
                            </Typography>
                        </MaterialRouterLink>
                    </Box>
                )}
                <Box className="grow" />
                <ProfileMenu />
            </Toolbar>
        </AppBar>
    )
}

export default CrmAppBar
