import { FC } from "react"
import { Route, Switch } from "react-router-dom"
import AdminRoutes from "./AdminRoutes"
import PublicRoutes from "./PublicRoutes"

type Props = {}

const Routes: FC<Props> = (props) => {
    return (
        <Switch>
            <Route path="/admin">
                <AdminRoutes />
            </Route>
            <Route path="/">
                <PublicRoutes />
            </Route>
        </Switch>
    )
}

export default Routes
