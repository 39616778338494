import { useField, useFormikContext } from "formik"
import { ReactNode } from "react"

/**
 * Returns all helpers and objects that are needed to create a component for a formik form
 * @param name name of the form field
 * @param disabled is the component disabled?
 * @returns
 * - field: props for a normal input field
 * - fieldHelper: helpers to mark a element touched and set the value manually withouth firing an event through field
 * - isDisabled: true if disabled trough the argument or the form itself
 * - showError: there is an error with the element
 * - errorToDisplay: the error that should be displayed
 */
const useFormikCrmInput = <T,>(name: string, disabled?: boolean) => {
    const [field, meta, fieldHelper] = useField<T>(name)
    const { status } = useFormikContext()

    const showError = !!meta.error && meta.touched
    const isDisabled = !!(status?.disabled || disabled)
    const errorToDisplay: ReactNode = showError ? meta.error : <>&nbsp;</>

    return [field, fieldHelper, isDisabled, showError, errorToDisplay] as const
}

export default useFormikCrmInput
