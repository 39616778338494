import { FC } from "react"
import { Redirect } from "react-router-dom"
import { useUserInfoState } from "../../store/userInfo/UserInfoStore"

type Props = {}

const RootRedirect: FC<Props> = (props) => {
    const userInfo = useUserInfoState()

    if (userInfo.loggedInUser) return <Redirect to="/admin" />

    return <Redirect to="/login" />
}

export default RootRedirect
