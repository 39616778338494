import { useSnackbar } from "notistack"
import { FC, useEffect } from "react"
import { Redirect } from "react-router-dom"
import de from "../../data/de"

type Props = {
    to?: string
}

const NotFoundRedirect: FC<Props> = ({ to = "/" }) => {
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        enqueueSnackbar(de.pageNotFound, { variant: "error" })
    }, [enqueueSnackbar])

    return <Redirect to={to} />
}

export default NotFoundRedirect
