import { useSnackbar } from "notistack"
import { FC, useEffect, useState } from "react"
import useLogout from "../../../api/account/useLogout"
import de from "../../../data/de"
import { useUserInfoState } from "../../../store/userInfo/UserInfoStore"
import SessionExpirationWarning from "./SessionExpirationWarning"

type Props = {}

/**
 * Checks if the user is currently logged in.
 * If there is no data in the user store, the user is redirected to the login via `useLogout()`
 */
const SessionCheck: FC<Props> = ({ children }) => {
    const userInfo = useUserInfoState()
    const [initialCheckDone, setInitialCheckDone] = useState(false)
    const logout = useLogout(true)
    const { enqueueSnackbar } = useSnackbar()

    //log the user out if there is no session
    useEffect(() => {
        if (initialCheckDone) return

        if (!userInfo.loggedInUser) {
            enqueueSnackbar(de.error.notLoggedIn, {
                variant: "error"
            })
            logout()
        }

        setInitialCheckDone(true)
    }, [enqueueSnackbar, initialCheckDone, logout, userInfo.loggedInUser])

    if (!initialCheckDone) return null

    return (
        <SessionExpirationWarning secondsBeforeWarning={30}>
            {children}
        </SessionExpirationWarning>
    )
}

export default SessionCheck
