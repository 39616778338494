import { FC, Suspense } from "react"
import { Backdrop, CircularProgress } from "@material-ui/core"

type Props = {}

/**
 * wrap lazy loaded components with this component
 * @param props
 */
const RouteWrapper: FC<Props> = ({ children }) => {
    return (
        <Suspense
            fallback={
                <Backdrop open>
                    <CircularProgress />
                </Backdrop>
            }
        >
            {children}
        </Suspense>
    )
}

export default RouteWrapper
