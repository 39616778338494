export type HttpStatusError =
    | "INTERNAL_SERVER_ERROR"
    | "NOT_FOUND"
    | "UNAUTHORIZED"
    | "CONFLICT"
    | "NOT_ALLOWED"
    | "FORBIDDEN"
    | "BAD_REQUEST"

export type SubError = {
    field: string
    message?: string
    errorCode?: string
}

export type ApiError = {
    httpStatus: HttpStatusError
    errorCode?: string
    timestamp: string
    message: string
    debugMessage?: string
    subErrors?: string[] | SubError[]
}

const isString = (arg: any) => typeof arg === "string" || arg instanceof String

/**
 * ApiError type guard
 * @param obj
 * @returns
 */
const isApiError = (obj: any): obj is ApiError =>
    !!obj && isString(obj.httpStatus) && isString(obj.timestamp)

export { isApiError }
