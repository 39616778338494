import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardMedia,
    Container,
    Hidden,
    Typography
} from "@material-ui/core"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Skeleton } from "@material-ui/lab"
import { FC, useCallback, useEffect, useState } from "react"
import de from "../../data/de"
import cardImage from "./cardImage.jpg"
import "./GuestCard.scss"

type Props = {
    title: string
    description?: string
}

const GuestCard: FC<Props> = ({ children, title, description }) => {
    const [src, setSrc] = useState("")

    const onLoadImage = useCallback(() => {
        if (src === "" && window.innerWidth > 1279) {
            const imageLoader = new Image()
            imageLoader.src = cardImage

            imageLoader.onload = () => {
                setSrc(cardImage)
            }
        } else if (src !== "" && window.innerWidth <= 1279) {
            setSrc("")
        }
    }, [src])

    useEffect(() => {
        window.addEventListener("resize", onLoadImage)
        onLoadImage()
        return () => window.removeEventListener("resize", onLoadImage)
    }, [onLoadImage])

    return (
        <Box component="main" className="root-crm-guest-card">
            <Container maxWidth="md">
                <Card className="card">
                    <CardContent className="cardContent">
                        <Box display="flex">
                            <Avatar className="avatar">
                                <LockOutlinedIcon fontSize="large" />
                            </Avatar>
                        </Box>
                        <Typography
                            className="title"
                            component="h1"
                            variant="h4"
                            color="textPrimary"
                        >
                            {title}
                        </Typography>
                        {description && (
                            <Typography
                                component="h2"
                                variant="subtitle1"
                                color="textPrimary"
                            >
                                {description}
                            </Typography>
                        )}
                        {children}
                        <Box mt={5}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                Copyright © W24 {new Date().getFullYear()}
                            </Typography>
                        </Box>
                    </CardContent>
                    <Hidden mdDown>
                        <CardMedia
                            title={de.name}
                            className="cardMedia"
                            image={src}
                        >
                            {!src && (
                                <Skeleton className="skeleton" height="100%" />
                            )}
                        </CardMedia>
                    </Hidden>
                </Card>
            </Container>
        </Box>
    )
}

export default GuestCard
