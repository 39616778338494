import { UserDto } from "../../api/user/types/UserDto"
import makeStore from "../makeStore"

export type State = {
    /**
     * null if there is no logged in user
     */
    loggedInUser: UserDto | null

    /**
     * string because a date is serialized as a string
     * hook useUserInfoState returns Date | null instead
     */
    sessionEnd: Date | null

    /**
     * session length in seconds
     */
    sessionLengthSeconds: number
}

const initialState: State = {
    loggedInUser: null,
    sessionEnd: null,
    sessionLengthSeconds: 0
}

type Action =
    | {
          type: "login"
          payload: { user: UserDto; sessionLengthSeconds: number }
      }
    | { type: "logout" }
    | { type: "update"; payload: UserDto }
    | { type: "refresh" }

/**
 * adds sessionLengthSeconds - 60s to the current time and returns that
 * 60s are subtracted so that there are no conflicts directly when the session ends
 * @param sessionLengthSeconds
 */
const calculateSessionEndTime = (sessionLengthSeconds: number) =>
    new Date(new Date().getTime() + (sessionLengthSeconds - 60) * 1000)

const userInfoReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "login":
            return {
                loggedInUser: action.payload.user,
                sessionEnd: calculateSessionEndTime(
                    action.payload.sessionLengthSeconds
                ),
                sessionLengthSeconds: action.payload.sessionLengthSeconds
            }

        case "logout":
            return {
                ...initialState
            }

        case "refresh":
            if (state.loggedInUser === null) return { ...state }

            return {
                ...state,
                sessionEnd: calculateSessionEndTime(state.sessionLengthSeconds)
            }

        case "update":
            return {
                ...state,
                loggedInUser: action.payload
            }
    }
}

const [UserInfoProvider, useUserInfoState, useUserInfoDispatch] = makeStore(
    userInfoReducer,
    initialState
)

export { UserInfoProvider, useUserInfoState, useUserInfoDispatch }
