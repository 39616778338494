import { useSnackbar } from "notistack"
import { useCallback } from "react"
import de from "../data/de"
import useLogout from "../api/account/useLogout"
import { HttpStatusError, isApiError } from "../api/types/ApiError"

type ErrorMessage = {
    httpStatus: HttpStatusError
    message: string
}

const errorMessages: ErrorMessage[] = [
    {
        httpStatus: "INTERNAL_SERVER_ERROR",
        message: de.error.generic
    },
    {
        httpStatus: "UNAUTHORIZED",
        message: de.error.loggedOut
    },
    {
        httpStatus: "NOT_FOUND",
        message: de.error.notFound
    },
    {
        httpStatus: "NOT_ALLOWED",
        message: de.error.notAllowed
    },
    {
        httpStatus: "FORBIDDEN",
        message: de.error.forbidden
    }
]

const useHandleError = () => {
    const { enqueueSnackbar } = useSnackbar()
    const logout = useLogout()

    const handleError = useCallback(
        (error: any) => {
            const handleGenericError = (error: any) => {
                enqueueSnackbar(de.error.generic, {
                    variant: "error"
                })
            }

            console.error(error)

            if (!isApiError(error)) {
                handleGenericError(error)
                return
            }

            const foundErrorMessage = errorMessages.find(
                (elem) => elem.httpStatus === error.httpStatus
            )

            //can happen if the session is ended manually, eg the user is deleted or deactivated
            if (error.httpStatus === "UNAUTHORIZED") {
                enqueueSnackbar(foundErrorMessage?.message, {
                    variant: "warning",
                    preventDuplicate: true
                })
                logout()
                return
            }

            if (foundErrorMessage)
                enqueueSnackbar(foundErrorMessage.message, {
                    variant: "error"
                })
            else handleGenericError(error)
        },
        [enqueueSnackbar, logout]
    )

    return [handleError] as const
}

export default useHandleError
