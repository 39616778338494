import { FC } from "react"
import { BrowserRouter } from "react-router-dom"
import { UserInfoProvider } from "../store/userInfo/UserInfoStore"
import SessionInitializer from "./routing/session/SessionInitializer"
import W24SnackbarProvider from "./snackbar/W24SnackbarProvider"
import StyleProvider from "./theme/StyleProvider"

type Props = {}

const GlobalWrapper: FC<Props> = ({ children }) => (
    <UserInfoProvider>
        <SessionInitializer>
            <BrowserRouter basename={process.env.REACT_APP_CONTEXT_PATH}>
                <StyleProvider>
                    <W24SnackbarProvider>{children}</W24SnackbarProvider>
                </StyleProvider>
            </BrowserRouter>
        </SessionInitializer>
    </UserInfoProvider>
)

export default GlobalWrapper
