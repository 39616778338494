import { useEffect } from "react"
import { useLocation } from "react-router"
import useSessionStorage from "./useSessionStorage"

/**
 * Stores the last path in session storage.
 * pathname + search query + hash
 * @param sessionStorageKey key used for the sessionStorage
 */
const useStoreLocationInSessionStorage = (sessionStorageKey: string) => {
    const [, setLastPath] = useSessionStorage(sessionStorageKey, "/")
    const location = useLocation()

    useEffect(() => {
        setLastPath(location.pathname + location.search + location.hash)
    }, [location.hash, location.pathname, location.search, setLastPath])
}

export default useStoreLocationInSessionStorage
