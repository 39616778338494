import { FC, lazy } from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AuthenticatedContainer from "../../components/authenticatedContainer/AuthenticatedContainer"
import useStoreLocationInSessionStorage from "../../hooks/useStoreLocationInSessionStorage"
import NotFoundRedirect from "./NotFoundRedirect"
import RouteWrapper from "./RouteWrapper"
import SessionCheck from "./session/SessionCheck"

const About = lazy(() => import("../../pages/admin/about/About"))
const Dashboard = lazy(() => import("../../pages/admin/dashboard/Dashboard"))
const MyProfile = lazy(() => import("../../pages/admin/profile/MyProfile"))
const AddUser = lazy(() => import("../../pages/admin/users/new/AddUser"))
const UserDetail = lazy(
    () => import("../../pages/admin/users/userId/UserDetail")
)
const UserList = lazy(() => import("../../pages/admin/users/UserList"))
const CompanyDetail = lazy(
    () => import("../../pages/admin/companies/companyId/CompanyDetail")
)
const CompanyList = lazy(
    () => import("../../pages/admin/companies/CompanyList")
)
const AddCompany = lazy(
    () => import("../../pages/admin/companies/new/AddCompany")
)
const OrderDetail = lazy(
    () => import("../../pages/admin/orders/orderId/OrderDetail")
)
const OrderList = lazy(() => import("../../pages/admin/orders/OrderList"))
const AddOrder = lazy(() => import("../../pages/admin/orders/new/AddOrder"))
const PlayoutList = lazy(() => import("../../pages/admin/playouts/PlayoutList"))
const AddPlayout = lazy(
    () => import("../../pages/admin/playouts/new/AddPlayout")
)
const PlayoutDetail = lazy(
    () => import("../../pages/admin/playouts/playoutId/PlayoutDetail")
)

type Props = {}

const AdminRoutes: FC<Props> = (props) => {
    const { path } = useRouteMatch()
    //store the last path in case the user is logged out or didn't have a session to begin with
    useStoreLocationInSessionStorage("lastLoggedInPath")

    return (
        <SessionCheck>
            <AuthenticatedContainer>
                <Switch>
                    <Route exact path={`${path}/dashboard`}>
                        <RouteWrapper>
                            <Dashboard />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/users`}>
                        <RouteWrapper>
                            <UserList />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/users/new`}>
                        <RouteWrapper>
                            <AddUser />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/users/:userId`}>
                        <RouteWrapper>
                            <UserDetail />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/profile`}>
                        <RouteWrapper>
                            <MyProfile />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/companies`}>
                        <RouteWrapper>
                            <CompanyList />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/companies/new`}>
                        <RouteWrapper>
                            <AddCompany />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/companies/:companyId`}>
                        <RouteWrapper>
                            <CompanyDetail />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/orders`}>
                        <RouteWrapper>
                            <OrderList />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/orders/new`}>
                        <RouteWrapper>
                            <AddOrder />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/orders/:orderId`}>
                        <RouteWrapper>
                            <OrderDetail />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/playouts`}>
                        <RouteWrapper>
                            <PlayoutList />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/playouts/new`}>
                        <RouteWrapper>
                            <AddPlayout />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/playouts/:playoutId`}>
                        <RouteWrapper>
                            <PlayoutDetail />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}/about`}>
                        <RouteWrapper>
                            <About />
                        </RouteWrapper>
                    </Route>
                    <Route exact path={`${path}`}>
                        <Redirect to={`${path}/dashboard`} />
                    </Route>
                    <Route>
                        <NotFoundRedirect to={path} />
                    </Route>
                </Switch>
            </AuthenticatedContainer>
        </SessionCheck>
    )
}

export default AdminRoutes
